import React, { useState } from 'react';
import { Button } from '@mui/material';
import { cookieBannerHolder } from './CookieBanner.module.scss';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import Cookies from 'universal-cookie';

const variants = {
  pre: {
    left: '-100%',
  },
  visible: {
    left: '0%',
    transition: {
      delay: 10,
      duration: 1.5,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  post: {
    left: '-100%',
  },
};

export default function CookieBanner() {
  const cookies = new Cookies();
  const acceptCookies = cookies.get('acceptCookies');

  const [showBanner, setShowBanner] = useState(!acceptCookies);

  const accept = () => {
    var dt = new Date();
    dt.setDate(dt.getDate() + 600);
    cookies.set('acceptCookies', 'true', { path: '/', expires: dt });
    setShowBanner(false);
  };

  return (
    <>
      <AnimatePresence>
        {showBanner && (
          <motion.div className={cookieBannerHolder} variants={variants} initial="pre" animate="visible" exit="post">
            <p>
              This site uses cookies. Read our <Link to="/privacy">Privacy policy</Link> to find out more
            </p>
            <Button variant="outlined" size="small" disableElevation onClick={() => accept()}>
              Accept
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
