import React from 'react';

import '../styles/normalize.css';
import '../styles/styles.scss';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CookieBanner from '../components/CookieBanner';

const black = '#000';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: black,
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     icon: {
    //       // color: lightGrey,
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       // color: lightGrey,
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     notchedOutline: {
    //       // borderColor: lightGrey,
    //     },
    //   },
    // },
    // MuiPickersDay: {
    //   styleOverrides: {
    //     root: {
    //       // color: midGrey,
    //     },
    //   },
    // },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // color: darkGrey,
        },
      },
    },
  },
  shape: {
    borderRadius: '0rem',
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: '"Source Code Pro", serif',
  },
  palette: {
    primary: {
      main: black,
    },
    // secondary: {
    //   // main: lightGrey,
    // },
    // text: {
    //   // primary: lightGrey,
    //   // secondary: lightGrey,
    // },
  },
});

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <main>{children}</main>
      <CookieBanner />
    </ThemeProvider>
  );
}
